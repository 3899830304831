import { Alert, Box, Button, Grid, TextField, Typography } from '@mui/material';
import { ContactFormModel, ContactFormValidation } from './ContactForm.props';
import { LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';
import React, { useEffect, useRef, useState } from 'react';

import SendIcon from '@mui/icons-material/Send';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import isLength from 'validator/lib/isLength';
import { useTranslation } from 'react-i18next';

const ContactForm = (): JSX.Element => {
  const { t } = useTranslation('contact');
  const [formModel, setFormModel] = useState({
    from: '',
    subject: '',
    content: '',
    code: '',
  } as ContactFormModel);
  const [formValidation, setFormValidation] = useState({} as ContactFormValidation);
  const [inProgress, setInProgress] = useState(false);
  const [status, setStatus] = useState<boolean | null>(null);
  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);
  const fromRef = useRef<HTMLDivElement>(null);
  const subjectRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const codeRef = useRef<HTMLDivElement>(null);
  const onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (fromRef.current?.contains(e.target)) {
      const fromError = isEmail(e.target.value) ? '' : t('contact_form_validation.invalid_email');
      setFormValidation({
        ...formValidation,
        from: fromError,
      });
      setFormModel({
        ...formModel,
        from: e.target.value,
      });
    }
    if (subjectRef.current?.contains(e.target)) {
      let subjectError = isEmpty(e.target.value) ? t('contact_form_validation.empty_subject') : '';
      if (!subjectError)
        subjectError = isLength(e.target.value, { min: 0, max: 100 })
          ? ''
          : t('contact_form_validation.subject_too_long');
      setFormValidation({
        ...formValidation,
        subject: subjectError,
      });
      setFormModel({
        ...formModel,
        subject: e.target.value,
      });
    }
    if (contentRef.current?.contains(e.target)) {
      let contentError = isEmpty(e.target.value) ? t('contact_form_validation.empty_content') : '';
      if (!contentError)
        contentError = isLength(e.target.value, { min: 0, max: 1500 })
          ? ''
          : t('contact_form_validation.content_too_long');
      setFormValidation({
        ...formValidation,
        content: contentError,
      });
      setFormModel({
        ...formModel,
        content: e.target.value,
      });
    }
    if (codeRef.current?.contains(e.target)) {
      const codeError = validateCaptcha(e.target.value, false) ? '' : t('contact_form_validation.invalid_code');
      setFormValidation({
        ...formValidation,
        code: codeError,
      });
      setFormModel({
        ...formModel,
        code: e.target.value,
      });
    }
    setStatus(null);
  };
  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
        {t('contact_form.title')}
      </Typography>
      <Grid container>
        <Grid item xs={0} sm={1} md={2} />
        <Grid item component="form" sm={10} md={8}>
          {status === true && (
            <Alert severity="success" sx={{ marginBottom: '10px' }} variant="filled">
              {t('alert.message_sent')}
            </Alert>
          )}
          {status === false && (
            <Alert severity="error" sx={{ marginBottom: '10px' }} variant="filled">
              {t('alert.message_error')}
            </Alert>
          )}
          <TextField
            label={t('contact_form.from')}
            fullWidth
            sx={{ marginBottom: '10px' }}
            error={!!formValidation.from}
            helperText={formValidation.from}
            onChange={onChange}
            ref={fromRef}
            value={formModel.from}
          />
          <TextField
            label={t('contact_form.subject')}
            fullWidth
            sx={{ marginBottom: '10px' }}
            error={!!formValidation.subject}
            helperText={formValidation.subject}
            onChange={onChange}
            ref={subjectRef}
            value={formModel.subject}
          />
          <TextField
            label={t('contact_form.content')}
            fullWidth
            multiline
            minRows={10}
            sx={{ marginBottom: '20px' }}
            error={!!formValidation.content}
            helperText={formValidation.content}
            onChange={onChange}
            ref={contentRef}
            value={formModel.content}
          />
          <Box textAlign="center" mb={3}>
            <LoadCanvasTemplateNoReload />
            <TextField
              label={t('contact_form.code')}
              error={!!formValidation.code}
              helperText={formValidation.code}
              onChange={onChange}
              ref={codeRef}
              value={formModel.code}
            />
          </Box>
          <Box textAlign="center">
            <Button
              type="submit"
              variant="contained"
              size="large"
              endIcon={<SendIcon />}
              disabled={
                status ||
                inProgress ||
                Object.values(formValidation).some((v) => v) ||
                Object.values(formModel).some((m) => !m)
              }
              onClick={async (e) => {
                e.preventDefault();
                setStatus(null);
                setInProgress(true);
                const response = await fetch('/api/contact', {
                  method: 'post',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(formModel),
                });
                setInProgress(false);
                if (response.status === 200) {
                  const json = await response.json();
                  if (json.success) {
                    setStatus(true);
                    setFormModel({
                      from: '',
                      subject: '',
                      content: '',
                      code: '',
                    });
                    setFormValidation({});
                  } else {
                    setStatus(false);
                  }
                } else {
                  setStatus(false);
                }
              }}
            >
              {t('contact_form.send')}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={0} sm={1} md={2} />
      </Grid>
    </Box>
  );
};
export default ContactForm;
