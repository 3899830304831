import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import Flag from 'react-flagkit';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = (): JSX.Element => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const { i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  const getCountryCode = (language: string) => {
    switch (language) {
      case 'pl':
        return 'PL';
      case 'en':
        return 'US';
    }
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  const setLanguage = (language: string) => {
    handleCloseMenu();
    i18n.changeLanguage(language);
  };

  return (
    <>
      <IconButton onClick={handleOpenMenu} size="large">
        <Flag country={getCountryCode(i18n.language)} style={{ border: '1px solid darkgray', height: 'auto' }} />
      </IconButton>
      <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={handleCloseMenu}>
        <MenuItem key="pl" onClick={() => setLanguage('pl')}>
          <Flag country={getCountryCode('pl')} style={{ border: '1px solid darkgray', height: 'auto' }} />
          <Typography sx={{ ml: 1 }}>polski</Typography>
        </MenuItem>
        <MenuItem key="en" onClick={() => setLanguage('en')}>
          <Flag country={getCountryCode('en')} style={{ border: '1px solid darkgray', height: 'auto' }} />
          <Typography sx={{ ml: 1 }}>english</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
export default LanguageSwitcher;
