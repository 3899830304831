import Automation from '../apps/Automation/Automation';
import CleanRoom from '../apps/CleanRoom/CleanRoom';
import Contact from '../apps/Contact/Contact';
import Main from '../apps/Main/Main';
import { Pages } from '../types/common';
import React from 'react';
import Realizations from '../apps/Realizations/Realizations';
import Ventilation from '../apps/Ventilation/Ventilation';

const pages = {
  main: {
    label: 'main',
    link: '/',
    component: <Main />,
    hiddenDesktop: true,
  },
  cleanroom: {
    label: 'cleanroom',
    link: '/cleanroom',
    component: <CleanRoom />,
  },
  ventilation: {
    label: 'ventilation',
    link: '/ventilation',
    component: <Ventilation />,
  },
  automation: {
    label: 'automation',
    link: '/automation',
    component: <Automation />,
  },
  realizations: {
    label: 'realizations',
    link: '/realizations',
    component: <Realizations />,
    hiddenDesktop: true,
  },
  contact: {
    label: 'contact',
    link: '/contact',
    component: <Contact />,
    hiddenDesktop: true,
  },
} as Pages;
export default pages;
