import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DarkModeButton from '../DarkModeButton/DarkModeButton';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { MenuDrawerContext } from '../../../../contexts/MenuDrawerContext';
import { NavBarProps } from '../../NavBarProps';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MenuDrawer = ({ pages, currentPage }: NavBarProps): JSX.Element => {
  const { open, setOpen } = React.useContext(MenuDrawerContext);
  const { t } = useTranslation('common');
  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={() => setOpen(!open)}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: { xs: 'block', md: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
      }}
    >
      <>
        <Box onClick={() => setOpen(!open)} sx={{ textAlign: 'center' }}>
          <List>
            <IconButton size='large'>
              <ArrowBackIcon />
            </IconButton>
            <Divider />
            {Object.keys(pages).map((p) => (
              <ListItem key={p} disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }} to={pages[p].link} component={NavLink}>
                  <ListItemText
                    primary={t(`nav.${pages[p].label}`)}
                    primaryTypographyProps={{ fontWeight: currentPage?.link === pages[p].link ? 'bold' : 'normal' }}
                    sx={{ textDecoration: currentPage?.link === pages[p].link ? 'underline' : undefined }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Divider />
        <Box>
          <DarkModeButton />
          <LanguageSwitcher />
        </Box>
      </>
    </Drawer>
  );
};
export default MenuDrawer;
