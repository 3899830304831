import { Box, Typography } from '@mui/material';

import Gallery from '../Gallery/Gallery';
import React from 'react';
import { RealizationProps } from './Realization.props';

const Realization = ({ title, directory, images }: RealizationProps): JSX.Element => {
  return (
    <Box mb={8}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
        {title}
      </Typography>
      <Gallery directory={directory} images={images} />
    </Box>
  );
};
export default Realization;
