import { Box, Paper, Typography } from '@mui/material';

import InfoCard from '../../components/InfoCard/InfoCard';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Ventilation = (): JSX.Element => {
  const { t } = useTranslation('ventilation');
  return (
    <Paper>
      <Box px={{ xs: 1, sm: 2, md: 3 }} py={3}>
        <Typography variant="h3" gutterBottom sx={{ display: { xs: 'none', md: 'block' }, textAlign: 'center' }}>
          {t('title')}
        </Typography>
        <Typography variant="body1" align="justify">
          {t('info')}
        </Typography>
        <InfoCard
          title={t('work_types.for_home.title')}
          info={t('work_types.for_home.text')}
          img="/images/ventilation/indoor-air-conditioner.jpg"
        />
        <InfoCard
          title={t('work_types.for_office.title')}
          info={t('work_types.for_office.text')}
          img="/images/ventilation/office-ventilation.jpg"
          reversed
        />
        <InfoCard
          title={t('work_types.for_industry.title')}
          info={t('work_types.for_industry.text')}
          img="/images/ventilation/industrial-ventilation.jpg"
        />
      </Box>
    </Paper>
  );
};

export default Ventilation;
