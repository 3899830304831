import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { IconButton } from '@mui/material';
import React from 'react';
import { Theme } from '../../../../types/common';
import { ThemeContext } from '../../../../contexts/ThemeContext';

const DarkModeButton = (): JSX.Element => {
  const { theme, setTheme } = React.useContext(ThemeContext);
  return (
    <IconButton onClick={() => setTheme(theme === Theme.Light ? Theme.Dark : Theme.Light)} size="large">
      {theme === Theme.Light ? <Brightness4Icon /> : <Brightness7Icon />}
    </IconButton>
  );
};
export default DarkModeButton;
