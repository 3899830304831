import React, { useState } from 'react';

import { LazyLoadImageProps } from './LazyLoadImage.props';
import { Skeleton } from '@mui/material';
import { useInView } from 'react-intersection-observer';

const LazyLoadImage = ({ ...props }: LazyLoadImageProps): JSX.Element => {
  const { ref, inView } = useInView();
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      {!loaded && (
        <Skeleton
          ref={ref}
          variant="rectangular"
          sx={{ height: '0', width: '100%', paddingBottom: '100%' }}
          animation={false}
        />
      )}
      {(inView || loaded) && (
        <img
          {...props}
          onLoad={(e) => {
            setLoaded(true);
            props.onLoad && props.onLoad(e);
          }}
          style={{ display: loaded ? 'block' : 'none' }}
        />
      )}
    </>
  );
};
export default LazyLoadImage;
