import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import React, { useContext, useMemo } from 'react';

import ConstructionIcon from '@mui/icons-material/Construction';
import { InfoCardProps } from './InfoCardProps';
import { NavLink } from 'react-router-dom';
import { Theme } from '../../types/common';
import { ThemeContext } from '../../contexts/ThemeContext';
import { useTranslation } from 'react-i18next';

const InfoCard = ({ title, info, img, reversed }: InfoCardProps): JSX.Element => {
  const themeObj = useTheme();
  const { theme } = useContext(ThemeContext);
  const shadowColor = useMemo(() => {
    switch (theme) {
      case Theme.Light:
        return themeObj.palette.grey[400];
      case Theme.Dark:
        return themeObj.palette.grey[900];
    }
  }, [theme, themeObj.palette.grey]);
  const { t } = useTranslation('common');
  return (
    <Grid container my={4} flexDirection={reversed ? 'row-reverse' : 'row'}>
      <Grid item xs={12} md={6} p={1} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'justify' }}>
          {info}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ textAlign: 'center', display: { xs: 'none', md: 'block' } }}>
          <Button
            variant="contained"
            color="info"
            endIcon={<ConstructionIcon />}
            to="/realizations"
            component={NavLink}
          >
            {t('nav.realizations')}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} p={1}>
        <img
          src={img}
          alt=""
          height="auto"
          width="100%"
          style={{
            objectFit: 'contain',
            filter: `drop-shadow(0 0 1px ${shadowColor}) drop-shadow(0 0 1px ${shadowColor})`,
          }}
        />
        <Box sx={{ textAlign: 'center', display: { xs: 'block', md: 'none' } }}>
          <Button
            variant="contained"
            color="info"
            endIcon={<ConstructionIcon />}
            to="/realizations"
            component={NavLink}
          >
            {t('nav.realizations')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
export default InfoCard;
