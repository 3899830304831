import { Box, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';

import ContactForm from './components/ContactForm/ContactForm';
import EmailIcon from '@mui/icons-material/Email';
import Obfuscate from 'react-obfuscate';
import PhoneIcon from '@mui/icons-material/Phone';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Contact = (): JSX.Element => {
  const { t } = useTranslation('contact');
  const count = new Date().getFullYear() - 1990;
  const get_years = () => {
    switch (count.toString()[1]) {
      case '0':
      case '1':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        return t('years_1');
      case '2':
      case '3':
      case '4':
        return t('years_2');
    }
  };
  return (
    <Paper>
      <Box px={{ xs: 1, sm: 2, md: 3 }} py={3}>
        <Typography variant="h3" gutterBottom sx={{ display: { xs: 'none', md: 'block' }, textAlign: 'center' }}>
          {t('title')}
        </Typography>
        <Typography variant="body1" align="justify">
          {t('company_info', { count: new Date().getFullYear() - 1990, years: get_years() })}
        </Typography>
        <Typography variant="body1" align="justify">
          {t('info')}
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText>
              <Obfuscate element="span" email="datawl@wp.pl" />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText>
              <Obfuscate element="span" tel="+48 503 100 672" />
            </ListItemText>
          </ListItem>
        </List>
      </Box>
      <ContactForm />
    </Paper>
  );
};

export default Contact;
