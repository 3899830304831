import { Box, Paper } from '@mui/material';

import React from 'react';

const Credits = (): JSX.Element => {
  return (
    <Paper>
      <Box px={{ xs: 1, sm: 2, md: 3 }} py={3}>
        <a href="https://www.freepik.com/free-photo/photo-automobile-production-line-welding-car-body-modern-car-assembly-plant-auto-industry-interior-hightech-factory-modern-production_26150271.htm#query=industrial%20automation&position=11&from_view=keyword&track=ais">
          Image by usertrmk
        </a>{' '}
        on Freepik Image by{' '}
        <a href="https://www.freepik.com/free-photo/3d-rendering-ventilation-system_22894128.htm#query=ventilation&position=12&from_view=search&track=sph">
          Freepik
        </a>
        <a href="https://www.freepik.com/free-photo/technologist-white-coat-with-laptop-his-hands-controls-production-process-dairy-shop-place-writing-technologist-with-laptop-computer-is-factory_26150361.htm#query=cleanroom&position=0&from_view=search&track=sph">
          Image by usertrmk
        </a>{' '}
        on Freepik
        <a href="https://www.freepik.com/free-ai-image/hand-checking-air-conditioner-room_84698757.htm#query=ventilation&position=17&from_view=search&track=sph&uuid=ca457567-628b-414d-84c0-7089ccc63ec3">
          Image By rorozoa
        </a>
        <a href="https://www.freepik.com/free-ai-image/modern-office-design-with-computer-equipment-inside-generated-by-ai_41450147.htm#fromView=search&term=office+air+conditioner&page=1&position=49&track=ais_ai_generated&regularType=ai">
          Image By vecstock
        </a>
        <a href="https://www.freepik.com/free-ai-image/industrial-metal-steel-pipes-ai-generated-image_43065951.htm#fromView=search&term=industrial+ventilation&page=1&position=0&track=ais_ai_generated&regularType=ai">
          Image By WangXiNa
        </a>
        Image by{' '}
        <a href="https://www.freepik.com/free-photo/flat-lay-circuit-board-close-up_20282411.htm#query=inverter%20motors&position=7&from_view=search&track=ais&uuid=1e75f3d3-f581-48c4-820d-14eb8dd929b3">
          Freepik
        </a>
        <a href="https://www.freepik.com/free-vector/manufacturing-work-isometric-composition_6372383.htm#query=industrial%20automation&position=37&from_view=search&track=ais&uuid=eef9fd83-aa1f-4895-944b-25849a856dd3">
          Image by macrovector
        </a>{' '}
        on Freepik
        <a href="https://www.freepik.com/free-photo/technologist-white-coat-with-laptop-his-hands-controls-production-process-digital-screen-dairy-shop-quality-control-dairy-plant_26151364.htm#query=industrial%20control%20box&position=2&from_view=search&track=ais&uuid=0bab827e-f156-4eec-9375-8413c5404584">
          Image by usertrmk
        </a>{' '}
        on Freepik
      </Box>
    </Paper>
  );
};
export default Credits;
