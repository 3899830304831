import { AppBar, Container } from '@mui/material';

import DesktopContent from './components/DesktopContent/DesktopContent';
import HideOnScroll from '../HideOnScroll/HideOnScroll';
import MobileContent from './components/MobileContent/MobileContent';
import React from 'react';

const Footer = (): JSX.Element => {
  return (
    <HideOnScroll direction="up">
      <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
        <Container maxWidth="xl" sx={{ display: 'flex' }}>
          <>
            <MobileContent />
            <DesktopContent />
          </>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
};
export default Footer;
