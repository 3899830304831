import { Box, Button, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

import ContactPageIcon from '@mui/icons-material/ContactPage';
import React from 'react';
import pages from '../../../../utils/pages';
import { useTranslation } from 'react-i18next';

const MobileContent = (): JSX.Element => {
  const { t } = useTranslation('common');
  const location = useLocation();
  return (
    <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}>
      {location.pathname !== pages.contact.link && (
        <Button
          variant="text"
          color="inherit"
          size="large"
          fullWidth
          startIcon={<ContactPageIcon />}
          to="/contact"
          component={NavLink}
        >
          {t('contact_us')}
        </Button>
      )}
      {location.pathname === pages.contact.link && <Typography>{t('all_rights_reserved')}</Typography>}
    </Box>
  );
};
export default MobileContent;
