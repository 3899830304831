import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

import Logo from './components/Logo/Logo';
import { NavLink } from 'react-router-dom';
import React from 'react';
import pages from '../../utils/pages';
import { useTranslation } from 'react-i18next';

const Main = (): JSX.Element => {
  const { t } = useTranslation('common');
  return (
    <Box>
      <Paper
        sx={{
          minHeight: 512,
          backgroundImage: "url('/images/ventilation.jpg')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Logo />
      </Paper>
      <Grid container mt={1} mb={5}>
        <Grid
          item
          xs={12}
          md={4}
          mb={1}
          component={Card}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CardHeader title={t('nav.cleanroom')} />
          <CardMedia
            component="img"
            image="/images/cleanroom/cleanroom.jpg"
            sx={{ height: 300, objectFit: 'contain' }}
          />
          <CardContent>
            <Typography variant="body1" color="text.secondary">
              {t('info.cleanroom')}
            </Typography>
          </CardContent>
          <CardActions disableSpacing sx={{ mt: 'auto' }}>
            <Button size="large" to={pages.cleanroom.link} component={NavLink}>
              {t('learn_more')}
            </Button>
          </CardActions>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          mb={1}
          component={Card}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CardHeader title={t('nav.ventilation')} />
          <CardMedia
            component="img"
            image="/images/ventilation/ventilation.jpg"
            sx={{ height: 300, objectFit: 'contain' }}
          />
          <CardContent>
            <Typography variant="body1" color="text.secondary">
              {t('info.ventilation')}
            </Typography>
          </CardContent>
          <CardActions disableSpacing sx={{ mt: 'auto' }}>
            <Button size="large" to={pages.ventilation.link} component={NavLink}>
              {t('learn_more')}
            </Button>
          </CardActions>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          mb={1}
          component={Card}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CardHeader title={t('nav.automation')} />
          <CardMedia
            component="img"
            image="/images/automation/automation.jpg"
            sx={{ height: 300, objectFit: 'contain' }}
          />
          <CardContent>
            <Typography variant="body1" color="text.secondary">
              {t('info.automation')}
            </Typography>
          </CardContent>
          <CardActions disableSpacing sx={{ mt: 'auto' }}>
            <Button size="large" to={pages.automation.link} component={NavLink}>
              {t('learn_more')}
            </Button>
          </CardActions>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
