import { Box, Button, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

import ContactPageIcon from '@mui/icons-material/ContactPage';
import React from 'react';
import pages from '../../../../utils/pages';
import { useTranslation } from 'react-i18next';

const DesktopContent = (): JSX.Element => {
  const { t } = useTranslation('common');
  const location = useLocation();
  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
        <Typography>{t('all_rights_reserved')}</Typography>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Button variant="text" color="inherit" size="small" to="/credits" component={NavLink}>
          credits
        </Button>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }} />
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        {location.pathname !== pages.contact.link && (
          <Button
            variant="contained"
            color="info"
            size="large"
            startIcon={<ContactPageIcon />}
            to="/contact"
            component={NavLink}
          >
            {t('contact_us')}
          </Button>
        )}
      </Box>
    </>
  );
};
export default DesktopContent;
