import { Slide, useScrollTrigger } from '@mui/material';

import { HideOnScrollProps } from './HideOnScrollProps';
import React from 'react';

const HideOnScroll = ({ children, direction }: HideOnScrollProps): JSX.Element => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction={direction} in={!trigger}>
      {children}
    </Slide>
  );
};
export default HideOnScroll;
