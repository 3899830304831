import { Box, Paper, Typography } from '@mui/material';

import InfoCard from '../../components/InfoCard/InfoCard';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Automation = (): JSX.Element => {
  const { t } = useTranslation('automation');
  return (
    <Paper>
      <Box px={{ xs: 1, sm: 2, md: 3 }} py={3}>
        <Typography variant="h3" gutterBottom sx={{ display: { xs: 'none', md: 'block' }, textAlign: 'center' }}>
          {t('title')}
        </Typography>
        <Typography variant="body1" align="justify">
          {t('info')}
        </Typography>
        <InfoCard title={t('inverters.title')} info={t('inverters.text')} img="/images/automation/inverter.jpg" />
        <InfoCard title={t('plc.title')} info={t('plc.text')} img="/images/automation/plc.jpg" reversed />
        <InfoCard title={t('scada.title')} info={t('scada.text')} img="/images/automation/scada.jpg" />
      </Box>
    </Paper>
  );
};

export default Automation;
