import React, { ReactNode, createContext, useState } from 'react';

type MenuDrawerContextType = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MenuDrawerContext = createContext<MenuDrawerContextType>({} as MenuDrawerContextType);

type MenuDrawerProviderProps = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: MenuDrawerProviderProps) {
  const [open, setOpen] = useState<boolean>(false);

  return <MenuDrawerContext.Provider value={{ open, setOpen }}>{children}</MenuDrawerContext.Provider>;
}
