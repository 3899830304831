import React, { ReactNode, createContext, useEffect, useState } from 'react';

import { Theme } from '../types/common';
import { useMediaQuery } from '@mui/material';

type ThemeContextType = {
  theme: Theme;
  setTheme: React.Dispatch<React.SetStateAction<Theme>>;
};

export const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);

type ThemeProviderProps = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: ThemeProviderProps) {
  let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const rememberedState = localStorage.getItem('dark_mode');
  switch (rememberedState) {
    case 'true':
      prefersDarkMode = true;
      break;
    case 'false':
      prefersDarkMode = false;
      break;
  }
  const [theme, setTheme] = useState<Theme>(prefersDarkMode ? Theme.Dark : Theme.Light);

  useEffect(() => localStorage.setItem('dark_mode', (theme === Theme.Dark).toString()), [theme]);

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
}
