import LanguageDetector from 'i18next-browser-languagedetector';
import automationEN from './locales/en/automation.json';
import automationPL from './locales/pl/automation.json';
import cleanroomEN from './locales/en/cleanroom.json';
import cleanroomPL from './locales/pl/cleanroom.json';
import commonEN from './locales/en/common.json';
import commonPL from './locales/pl/common.json';
import contactEN from './locales/en/contact.json';
import contactPL from './locales/pl/contact.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import realizationsEN from './locales/en/realizations.json';
import realizationsPL from './locales/pl/realizations.json';
import ventilationEN from './locales/en/ventilation.json';
import ventilationPL from './locales/pl/ventilation.json';

const detectionOptions = {
  order: ['localStorage'],
  lookupSessionStorage: 'locale',
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    ns: ['common'],
    defaultNS: 'common',
    fallbackLng: 'pl',
    keySeparator: '.',
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        cleanroom: cleanroomEN,
        ventilation: ventilationEN,
        automation: automationEN,
        realizations: realizationsEN,
        common: commonEN,
        contact: contactEN,
      },
      pl: {
        cleanroom: cleanroomPL,
        ventilation: ventilationPL,
        automation: automationPL,
        realizations: realizationsPL,
        common: commonPL,
        contact: contactPL,
      },
    },
    detection: detectionOptions,
    react: {
      useSuspense: false,
    },
  });

export default i18n;
