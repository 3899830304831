import { Grid, useTheme } from '@mui/material';
import React, { useContext, useMemo } from 'react';

import { GalleryProps } from './Gallery.props';
import LazyLoadImage from '../LazyLoadImage/LazyLoadImage';
import { Theme } from '../../../../types/common';
import { ThemeContext } from '../../../../contexts/ThemeContext';

const Gallery = ({ directory, images }: GalleryProps): JSX.Element => {
  const themeObj = useTheme();
  const { theme } = useContext(ThemeContext);
  const shadowColor = useMemo(() => {
    switch (theme) {
      case Theme.Light:
        return themeObj.palette.grey[400];
      case Theme.Dark:
        return themeObj.palette.grey[900];
    }
  }, [theme, themeObj.palette.grey]);
  return (
    <Grid container spacing={1}>
      {images.map((i) => {
        return (
          <Grid key={`${directory}/${i}`} item xs={12} md={6} lg={4}>
            <LazyLoadImage
              src={`${directory}/${i}`}
              alt=""
              height="auto"
              width="100%"
              style={{
                objectFit: 'contain',
                filter: `drop-shadow(0 0 1px ${shadowColor}) drop-shadow(0 0 1px ${shadowColor})`,
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
export default Gallery;
