import { Box, Collapse, IconButton, Typography, useScrollTrigger } from '@mui/material';

import DarkModeButton from '../DarkModeButton/DarkModeButton';
import MenuDrawer from '../MenuDrawer/MenuDrawer';
import { MenuDrawerContext } from '../../../../contexts/MenuDrawerContext';
import MenuIcon from '@mui/icons-material/Menu';
import { NavBarProps } from '../../NavBarProps';
import React from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

const MobileContent = ({ pages, currentPage }: NavBarProps): JSX.Element => {
  const { open, setOpen } = React.useContext(MenuDrawerContext);
  const trigger = useScrollTrigger({
    threshold: 0,
    disableHysteresis: true,
  });
  const { t } = useTranslation('common');
  return (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size="large"
          aria-label="menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => setOpen(!open)}
          color="inherit"
          sx={{
            backgroundColor: 'inherit',
          }}
        >
          <MenuIcon />
        </IconButton>
        <MenuDrawer pages={pages} currentPage={currentPage} />
      </Box>
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
        }}
      >
        <TransitionGroup>
          {!trigger && (
            <Collapse>
              <Typography
                variant="h5"
                noWrap
                align="center"
                sx={{
                  mr: 2,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                MAX-WENT
              </Typography>
            </Collapse>
          )}
          {trigger && (
            <Collapse>
              <Typography
                noWrap
                align="center"
                sx={{
                  mr: 2,
                }}
              >
                {t(`nav.${currentPage ? currentPage.label : "unknown"}`)}
              </Typography>
            </Collapse>
          )}
        </TransitionGroup>
      </Box>
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          flexGrow: 1,
        }}
      >
      </Box>
    </>
  );
};
export default MobileContent;
