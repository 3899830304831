import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Container, styled } from '@mui/material';
import React, { useContext, useEffect, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { AppContainerProps } from './AppContainer.props';
import Credits from '../apps/Credits/Credits';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from '../components/Footer/Footer';
import NavBar from '../components/NavBar/NavBar';
import { Theme } from '../types/common';
import { ThemeContext } from '../contexts/ThemeContext';
import pages from '../utils/pages';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const AppContainerChildren = (): JSX.Element => {
  const location = useLocation();
  const currentPage = Object.entries(pages)
    .filter(([k, v]) => v.link === location.pathname)
    .map(([k, v]) => v);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Container maxWidth="xl">
      <NavBar pages={pages} currentPage={currentPage.length > 0 ? currentPage[0] : undefined} />
      <Offset />
      <Routes>
        {Object.keys(pages).map((p) => (
          <Route key={p} path={pages[p].link} element={pages[p].component} />
        ))}
        <Route key="credits" path="/credits" element={<Credits />} />
      </Routes>
      <Footer />
    </Container>
  );
};

const AppContainer = ({ baseUrl }: AppContainerProps): JSX.Element => {
  const { theme } = useContext(ThemeContext);
  const currentTheme = useMemo(() => {
    switch (theme) {
      case Theme.Light:
        return createTheme({
          palette: {
            mode: 'light',
            background: {
              paper: '#e4e6eb',
              default: '#e8eaed',
            },
          },
        });
      case Theme.Dark:
        return createTheme({
          palette: {
            mode: 'dark',
          },
        });
    }
  }, [theme]);
  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <BrowserRouter basename={baseUrl}>
        <AppContainerChildren />
      </BrowserRouter>
    </ThemeProvider>
  );
};
export default AppContainer;
