export type Page = {
  label: string;
  link: string;
  component: JSX.Element;
  hiddenDesktop?: boolean;
};

export type Pages = Record<string, Page>;

export enum Theme {
  Light,
  Dark,
}
