import { AppBar, Container, Toolbar } from '@mui/material';

import DesktopContent from './components/DesktopContent/DesktopContent';
import HideOnScroll from '../HideOnScroll/HideOnScroll';
import MobileContent from './components/MobileContent/MobileContent';
import { NavBarProps } from './NavBarProps';
import React from 'react';

const NavBarContainer = ({ pages, currentPage }: NavBarProps): JSX.Element => {
  return (
    <Container maxWidth="xl">
      <Toolbar disableGutters>
        <MobileContent pages={pages} currentPage={currentPage} />
        <DesktopContent pages={pages} currentPage={currentPage} />
      </Toolbar>
    </Container>
  );
};

const NavBar = ({ pages, currentPage }: NavBarProps): JSX.Element => {
  return (
    <HideOnScroll direction="down">
      <AppBar position="fixed" component="nav">
        <NavBarContainer pages={pages} currentPage={currentPage} />
      </AppBar>
    </HideOnScroll>
  );
};
export default NavBar;
