import { Box, Paper, Typography } from '@mui/material';

import React from 'react';
import Realization from './components/Realization/Realization';
import { useTranslation } from 'react-i18next';

const Realizations = (): JSX.Element => {
  const { t } = useTranslation('realizations');
  return (
    <Paper>
      <Box px={{ xs: 1, sm: 2, md: 3 }} py={3}>
        <Typography variant="h3" gutterBottom sx={{ display: { xs: 'none', md: 'block' }, textAlign: 'center' }}>
          {t('title')}
        </Typography>
        <Typography variant="body1" align="justify">
          {t('info')}
        </Typography>
        <Realization
          title={t('cleanroom.title')}
          directory="/images/gallery/Cleanroom"
          images={[
            '20231120_134704.jpg',
            '20231120_134706.jpg',
            '20231120_134714.jpg',
            '20231120_134716.jpg',
            '20231120_134720.jpg',
            '20231120_134724.jpg',
            '20231120_134736.jpg',
            '20231120_134738.jpg',
            '20231120_134744.jpg',
            '20231120_134747.jpg',
            '20231120_134759.jpg',
            '20231120_134805.jpg',
            '20231120_134837.jpg',
            '20231120_134839.jpg',
            '20231120_134843.jpg',
            '20231120_134850.jpg',
            '20231120_134854.jpg',
            '20231120_134904.jpg',
            '20231120_134909.jpg',
            '20231120_134913.jpg',
            '20231120_134915.jpg',
            '20231120_134921.jpg',
            '20231120_134923.jpg',
            '20231120_134924.jpg',
            '20231120_134927.jpg',
            '20231120_134937.jpg',
            '20231120_134939.jpg',
            '20231120_134947.jpg',
            '20231120_134951.jpg',
            '20231120_134954.jpg',
            '20231120_135001.jpg',
            '20231120_135002.jpg',
            '20231120_135004.jpg',
            '20231120_135007.jpg',
            '20231120_135010.jpg',
            '20231120_135051.jpg',
            '20231120_135056.jpg',
            '20231120_135057.jpg',
            '20231120_135108.jpg',
            '20231120_135119.jpg',
            '20231120_135121.jpg',
            '20231120_135123.jpg',
          ]}
        />
        <Realization
          title={t('andur.title')}
          directory="/images/gallery/Andur"
          images={['andur1.jpg', 'andur2.jpg', 'andur3.jpg', 'andur4.jpg']}
        />
        <Realization
          title={t('waszkowo.title')}
          directory="/images/gallery/Waszkowo"
          images={[
            '20120926_143306.jpg',
            '20120929_092353.jpg',
            '20120929_092434.jpg',
            '20120929_092446.jpg',
            '20120929_092647.jpg',
            '20120929_092918.jpg',
            '20120929_093041.jpg',
            '20120929_093600.jpg',
            'Zdjecie-0010.jpg',
            'Zdjecie-0011.jpg',
            'Zdjecie-0012.jpg',
          ]}
        />
        <Realization
          title={t('renex.title')}
          directory="/images/gallery/Renex"
          images={[
            '20140324_113502.jpg',
            '20140324_113533.jpg',
            '20140324_113537.jpg',
            '20140324_113542.jpg',
            '20140324_113631.jpg',
            '20140324_113638.jpg',
            '20140324_113644.jpg',
            '20140324_113733.jpg',
            '20140324_113741.jpg',
          ]}
        />
        <Realization
          title={t('pta.title')}
          directory="/images/gallery/PTA Włocławek"
          images={[
            '20120823_114745.jpg',
            '20120823_114749.jpg',
            '20120823_114755.jpg',
            '20120823_114800.jpg',
            'Ekipa przy montażu.jpg',
            'KLIMATYZACJA I WENTYLACJA STEROWNI.jpg',
            'Klimatyzatory Mitsubishi Heavy.jpg',
            'KOMPRESOROWNIA.jpg',
            'Transport klimatyzatorów.jpg',
            'Wytwórnia PTA Włocławek.jpg',
          ]}
        />
        <Realization
          title={t('teatr.title')}
          directory="/images/gallery/Teatr impresaryjny"
          images={[
            '20140120_105431.jpg',
            '20140120_105442.jpg',
            '20140120_105453.jpg',
            '20140120_105456.jpg',
            '20140120_105504.jpg',
            '20140120_105507.jpg',
            '20140120_105529.jpg',
            '20140120_105550.jpg',
            '20140120_105617.jpg',
            '20140120_105620.jpg',
            '20140120_105643.jpg',
            '20140120_105712.jpg',
            '20140120_105717.jpg',
            '20140120_105723.jpg',
            '20140120_105753.jpg',
            '20140120_105756.jpg',
            '20140120_105937.jpg',
            '20140120_105953.jpg',
            '20140120_110006.jpg',
            '20140120_110035.jpg',
            '20140120_122123.jpg',
            'Zdjęcie-0089.jpg',
            'Zdjęcie-0090.jpg',
          ]}
        />
        <Realization
          title={t('urzad_zakrzewo.title')}
          directory="/images/gallery/Urząd gminy Zakrzewo"
          images={[
            '20120730_143609.jpg',
            '20120730_143626.jpg',
            '20120730_143659.jpg',
            '20120730_143714.jpg',
            '20120730_143742.jpg',
          ]}
        />
      </Box>
    </Paper>
  );
};
export default Realizations;
