import { Box, Typography } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

const Logo = (): JSX.Element => {
  const { t } = useTranslation('common');
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '512px',
        background: 'radial-gradient(circle at 50% 50%, rgb(0, 0, 0) 120px, rgba(255, 255, 255, 0) 300px)',
      }}
    >
      <Box px={{ xs: 3, sm: 0 }}>
        <Box>
          <Typography
            variant="h4"
            noWrap
            sx={{
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'white',
              textDecoration: 'none',
            }}
          >
            MAX-WENT
          </Typography>
          <img src="/images/machine.svg" alt="ventilation" />
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Box>
            <Typography variant="h5" noWrap sx={{ color: 'white' }}>
              {t('nav.cleanroom')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" noWrap sx={{ color: 'white' }}>
              {t('nav.ventilation')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" noWrap sx={{ color: 'white' }}>
              {t('nav.automation')}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <Box>
            <Typography variant="h6" noWrap sx={{ color: 'white' }}>
              {t('nav.cleanroom')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" noWrap sx={{ color: 'white' }}>
              {t('nav.ventilation')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" noWrap sx={{ color: 'white' }}>
              {t('nav.automation')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Logo;
