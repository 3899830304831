import { Box, Button, Typography } from '@mui/material';

import DarkModeButton from '../DarkModeButton/DarkModeButton';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { NavBarProps } from '../../NavBarProps';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DesktopContent = ({ pages, currentPage }: NavBarProps): JSX.Element => {
  const { t } = useTranslation('common');
  return (
    <>
      <Typography
        variant="h6"
        noWrap
        sx={{
          mr: 2,
          display: { xs: 'none', md: 'flex' },
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'inherit',
          textDecoration: 'none',
        }}
        to={pages.main.link}
        component={NavLink}
      >
        MAX-WENT
      </Typography>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        {Object.keys(pages)
          .filter((p) => !pages[p].hiddenDesktop)
          .map((p) => (
            <Button
              key={p}
              to={pages[p].link}
              component={NavLink}
              sx={{
                my: 2,
                display: 'block',
                marginLeft: 1,
                fontWeight: currentPage?.link === pages[p].link ? 'bold' : 'normal',
                textDecoration: currentPage?.link === pages[p].link ? 'underline' : undefined,
                whiteSpace: 'nowrap',
              }}
              variant="text"
              color="inherit"
              size="large"
            >
              {t(`nav.${p}`)}
            </Button>
          ))}
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <DarkModeButton />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <LanguageSwitcher />
      </Box>
    </>
  );
};
export default DesktopContent;
